<template>
  <div style="height: 100%">
 <div class="checklist-edit">
   <div class="step-box">
     <head-layout :show-icon="false" head-title="处理进度" ></head-layout>
     <el-steps direction="vertical" :active="3" style="margin-top: 30px;align-items: center;height: 50%;">
       <el-step title="上报"></el-step>
       <el-step title="评估"></el-step>
       <el-step title="整改"></el-step>
       <el-step title="验收"></el-step>
     </el-steps>
   </div>
  <div class="form-box">
    <div class="">
      <head-layout head-title="文明规范事项信息" :head-btn-options="btnOptions" @head-cancel="headCancel"></head-layout>
      <div class="formContentBox">
        <div class="formMain">
          <div style="padding: 0 12px">
            <el-form ref="dataForm" :model="dataForm" label-width="150px" disabled>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="事项编号" prop="cvCode">
                    <el-input v-model="dataForm.cvCode" disabled placeholder="请输入事项编号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="地点" prop="prjName">
                    <el-input  v-model="dataForm.location"
                               placeholder="请填写地点"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="报告人" prop="reportUserName">
                    <el-input v-model="dataForm.reportUserName" placeholder="请填写报告人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报告部门" prop="reportDeptName">
                    <el-input v-model="dataForm.reportDeptName" placeholder="请填写报告部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报告时间" prop="reportDate">
                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm.reportDate" placeholder="请选择报告时间"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="描述" prop="cvDesc">
                    <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit v-model="dataForm.cvDesc"
                              placeholder="请填写描述"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="事项来源" prop="sourceType">
                    <avue-select :props="{
              label: 'dictValue',
              value: 'dictKey',
            }" v-model="dataForm.sourceType" placeholder="请选择事项来源" type="tree"
                                 :dic="HD_SOURCE_TYPE"></avue-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="现场" prop="prjAs">
                    <div class="content">
                      <div
                        v-for="(item, index) in treeData"
                        :key="item.id"
                        class="img-content"
                        @click="getNodeClick(item)"
                      >
                        <img :src="item.thumbnailLink" alt=""/>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <head-layout head-title="事项评估"></head-layout>
      <div class="formContentBox">
        <div class="formMain">
          <div style="padding: 0 12px">
            <el-form ref="dataForm" :model="dataForm2" label-width="150px" disabled>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="事项核实" prop="passFlag">
                    <el-radio v-model="dataForm2.passFlag" label="y">核实通过</el-radio>
                    <el-radio v-model="dataForm2.passFlag" label="n">核实不通过</el-radio>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm2.passFlag == 'n' || dataForm2.passFlag == 'y'">
                  <el-form-item label="评估时间" prop="assessmentTime">
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm2.assessmentTime"
                                    placeholder="请选择评估时间"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="责任部门" prop="respDeptName">
                    <el-input v-model="dataForm1.respDeptName" placeholder="请选择责任部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="责任人" prop="respUserName">
                    <el-input v-model="dataForm2.respUserName" placeholder="请选择责任人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="提交整改计划" prop="rectifyPlanFlag">
                    <el-checkbox false-label="n" true-label="y" v-model="dataForm2.rectifyPlanFlag"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="整改部门" prop="rectifyDeptName">
                    <el-input v-model="dataForm2.rectifyDeptName" placeholder="请选择整改部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="整改人" prop="sourceType">
                    <el-input v-model="dataForm2.rectifyUserName" placeholder="请选择实际整改人"></el-input>
                  </el-form-item>
                </el-col>

              </el-row>
              <el-row>
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="验收部门" prop="acceptDeptName">
                    <el-input v-model="dataForm2.acceptDeptName" placeholder="请选择验收部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="验收人" prop="acceptUserName">
                    <el-input v-model="dataForm2.acceptUserName" placeholder="请选择验收人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="整改期限" prop="rectifyLimitDate">
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm2.rectifyLimitDate"
                                    placeholder="请选择整改期限"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" v-if="dataForm2.passFlag == 'y'">
                  <el-form-item label="整改要求" prop="rectifyRequest">
                    <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit
                              v-model="dataForm2.rectifyRequest" placeholder="请填写整改要求"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hide != 'hide'" class="">
     <div>
       <head-layout head-title="事项整改" :head-btn-options="headBtnOptions"  @head-saveBack="headSave(true)"  @head-save="headSave(false)">
         <template slot="right">
           <el-dropdown >
             <el-button type="primary" size="mini">
               整改历史
             </el-button>
             <el-dropdown-menu slot="dropdown">
               <el-dropdown-item @click.native="handleDropdown(item)" v-for="item in historyList" class="list-decoration">{{item.rectifyDate}}</el-dropdown-item>
             </el-dropdown-menu>
           </el-dropdown>
         </template>
       </head-layout>
     </div>
      <div class="formContentBox">
        <div class="formMain">
          <div style="padding: 0 12px">
            <el-form ref="dataForm1" :model="dataForm1" label-width="150px" :disabled="formType == 'view'" :rules="formType == 'view' ? {} :rules">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="完成时间" prop="rectifyDate">
                    <el-date-picker type="datetime"  value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm1.rectifyDate"  placeholder="请选择完成时间"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8" >
                  <el-form-item label="实际整改人" prop="actualRectifyUserName">
                    <el-input v-model="dataForm1.actualRectifyUserName" @focus="respUserShow=true" placeholder="请选择实际整改人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="整改措施" prop="remark">
                    <el-input type="textarea" maxlength="500" :autosize="{ minRows: 2}" show-word-limit v-model="dataForm1.remark" placeholder="请填写整改要求" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row >
                <el-col :span="24">
                  <el-form-item label="整改计划" prop="treeData3" >
                    <el-upload
                      :class="formType == 'view' ? 'hide-input' :''"
                      :on-preview="handlePreview"
                      :disabled="formType == 'view'"
                      class="upload-demo"
                      accept=".rar,.zip,.doc,.docx,.pdf"
                      action="api/sinoma-resource/oss/endpoint/put-file"
                      :on-success="handleAvatarSuccess"
                      :before-upload="checkFileType"
                      :on-remove="handleRemove"
                      :headers="headers"
                      multiple
                      :limit="1"
                      :file-list="treeData3"
                    >
                      <el-button size="small" type="primary"  v-if="formType != 'view'"
                      >点击上传
                      </el-button
                      >
                      <div slot="tip" class="el-upload__tip"  v-if="formType != 'view'">
                        支持扩展名：.rar .zip .doc .docx .pdf
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="整改结果" prop="treeData4" >
                    <el-upload
                      v-if="formType !== 'view'"
                      accept=".png,.jpg"
                      style="margin-bottom: 12px;display: flex; align-items: center;"
                      class="upload-demo"
                      action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
                      :show-file-list="false"
                      :before-upload="checkFileType1"
                      :on-success="handleAvatarSuccess1"
                      ref="uploadRef"
                      :headers="headers"
                    >
                      <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-upload"
                      >点击上传
                      </el-button>
                    </el-upload>
                    <div class="content">
                      <div
                        v-for="(item, index) in treeData4"
                        :key="item.id"
                        class="img-content"
                        @click="getNodeClick(item)"
                      >
                        <img
                          v-if="
                          item.extension == 'jpg' ||
                          item.extension == 'png' ||
                          item.extension == 'image/jpeg' ||
                          item.extension == 'image/png'
                        "
                          :src="item.link"
                          alt=""
                        />
                        <img
                          v-else
                          src="@/assets/images/dashboard-nodata.png"
                          alt=""
                        />
                        <i
                          v-if="formType !== 'view'"
                          class="el-icon-circle-close delete-icon"
                          @click.stop="handleRemove1(item, index)"
                        ></i>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
    <el-dialog title="人员选择" :visible.sync="respUserShow" width="80%" top="8vh">
      <UserDetpDialog ref="UserDetpDialog" @select-data="respUserFun"></UserDetpDialog>
    </el-dialog>
    <el-dialog title="整改历史" :visible.sync="historyShow" width="80%">
      <el-form ref="dataForm" :model="modelDataForm" label-width="150px" disabled >
        <el-row>
          <el-col :span="8">
            <el-form-item label="完成时间" prop="rectifyDate">
              <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="datetime"  v-model="modelDataForm.rectifyDate"  placeholder="请选择完成时间"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" >
            <el-form-item label="实际整改人" prop="actualRectifyUserName">
              <el-input v-model="modelDataForm.actualRectifyUserName"  placeholder="请选择实际整改人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="整改措施" prop="remark">
              <el-input type="textarea"  :autosize="{ minRows: 2}" show-word-limit v-model="modelDataForm.remark" placeholder="请填写整改要求" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="24">
            <el-form-item label="整改计划" prop="prjAs">
              <el-upload
                disabled
                :on-preview="handlePreview"
                class="upload-demo"
                :file-list="modelDataFormImgList"></el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="整改结果" prop="prjAs">
              <div class="content">
                <div
                  v-for="(item, index) in modelDataFormList"
                  :key="item.id"
                  class="img-content"
                  @click="getNodeClick(item)"
                >
                  <img
                    v-if="
                          item.extension == 'jpg' ||
                          item.extension == 'png' ||
                          item.extension == 'image/jpeg' ||
                          item.extension == 'image/png'
                        "
                    :src="item.link"
                    alt=""
                  />
                  <img
                    v-else
                    src="@/assets/images/dashboard-nodata.png"
                    alt=""
                  />
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
        v-dialog-drag
        title="附件预览"
        :modal="true"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :visible.sync="showFileDialog"
        width="70%"
    >
      <iframe
          ref="fileIframe"
          :src="fileUrl"
          frameborder="0"
          style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import * as  API from "@/api/civilizedNorm/rectify";
  import {detail} from "@/api/civilizedNorm/checklist";
  import {getToken} from "@/util/auth";
  import website from "@/config/website";
  import Template from "@/views/message/template/list.vue";
  import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog.vue";
  import {dateFormat} from "@/util/date";
  import {mapGetters} from "vuex";
  import snows from "@/util/snows";

  export default {
    components: {
      UserDetpDialog,
      Template
    },
    data() {
      return {
        hide:'',
        fileUrl: '',
        showFileDialog: false,
        historyShow:false,
        modelDataForm:{},
        modelDataFormImgList:[],
        modelDataFormList:[],
        treeData:[],
        historyList:[],
        treeData1:[],
        treeData3:[],
        treeData4:[],
        treeData2:[],
        sourceDic:[],
        hd_level:[],
        HD_SOURCE_TYPE:[],
        hd_type:[],
        formType: '',
        headTitle: '',
        mapBox: false,
        respUserShow: false,
        mapdata: '',
        dataForm: {},
        dataForm2: {},
        dataForm1: {
          treeData3:[],
          treeData4:[],
          rectifyDate:dateFormat(new Date())
        },
        tableData: [],
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        rules: {
          rectifyDate: [{
            required: true,
            message: '请选择完成时间',
            trigger: 'blur'
          }],
          treeData4: [{
            required: true,
            message: '请上传整改结果',
            trigger: 'blur'
          }],
          remark: [{
            required: true,
            message: '请输入整改措施',
            trigger: 'blur'
          }],
        },
        selectionList: [],
      }
    },
    computed: {
      ...mapGetters([
        'userInfo',
      ]),
      data() {
        return data
      },
      headers: function () {
        return {"Sinoma-Auth": getToken()};
      },
      btnOptions(){
        return [{
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        }]
      },
      headBtnOptions() {
        let result = [];
        if (['add', 'edit'].includes(this.formType)) {
          result.push({
            label: "保存并返回",
            emit: "head-saveBack",
            type: "button",
            btnOptType: "refer",
          },
              {
                emit: "head-save",
                type: "button",
                btnOptType: "save",
              }
          );
        }
        return result;
      },
    },
    mounted() {
      let type = this.$route.query.type;
      this.hide = this.$route.query.hide;
      this.formType = type;
      if (type == 'edit') {
        this.headTitle = '事项整改';
        this.dataForm.id = this.$route.query.id;
        this.dataForm1.actualRectifyUser=this.userInfo.user_id
        this.dataForm1.actualRectifyUserName=this.userInfo.real_name
        this.getPrjbasicinfoDetail();
      } else if (type == 'view') {
        this.headTitle = '事项整改';
        this.dataForm.id = this.$route.query.id;
        this.getPrjbasicinfoDetail();
      }
      this.getCode('HD_SOURCE_TYPE')
      this.getCode('hd_level')
      this.getCode('hd_type')

    },
    methods: {
      handlePreview(file){
        snows.downloadFile1(file.link,file.name)
      },
      handleDropdown(item){
        this.modelDataForm=item;
        this.modelDataFormImgList=JSON.parse(item.rectifyPlanUrl)
        this.modelDataFormList=JSON.parse(item.rectifyUrl)
        this.historyShow=true
      },
      respUserFun(row){
          this.dataForm1.actualRectifyUser=row.id
          this.dataForm1.actualRectifyUserName=row.realName
        this.respUserShow=false
        console.log("进行输出人员-------------------",row)
      },
      getCode(code){
        API.HD_SOURCE_TYPE(code).then(res=>{
          this[code]=res.data.data
        })
      },
      handleAvatarSuccess(res, file, fileList) {
        let list = [];
        fileList.map((i) => {
          if (i.response) {
            list.push({
              extension: i.response.data.type,
              id: i.response.attachId,
              name: i.name,
              link: i.response.data.link
            });
          } else {
            list.push({
              extension: i.type,
              id: i.attachId,
              link: i.link,
              name: i.name,
              url: i.url
            });
          }
        })
        this.treeData3 = list;
        this.$set(this.dataForm1,'treeData3',list)
        this.$refs.dataForm1.validateField('treeData3')
      },
      handleAvatarSuccess1(res, file, fileList) {
        this.treeData4.push({
          extension: file.raw.type,
          id: file.response.data.attachId,
          link: file.response.data.link,
          name: file.response.data.originalName,
          originalName: file.response.data.name,
        });
        this.$set(this.dataForm1,'treeData4',this.treeData4)
        this.$refs.dataForm1.validateField('treeData4')
      },
      checkFileType(file){
        const fileType = file.name.substring(file.name.lastIndexOf('.'));
        const isLt20M = file.size / 1024 / 1024 < 20;
        const fileTran=['.rar','.zip','.doc','.docx','.pdf'];
        const isJPG = fileTran.includes(fileType);

        if (!isJPG) {
          this.$message.warning('上传图片只能是 .rar  .zip .docx .pdf格式!');
        }
        if (!isLt20M) {
          this.$message.warning('上传文件大小不能超过 20MB!');
        }
        return isJPG && isLt20M;
      },
      checkFileType1(file){
        const fileType = file.name.substring(file.name.lastIndexOf('.'));
        const isLt20M = file.size / 1024 / 1024 < 20;
        const isJPG = (fileType === '.png' ||  fileType == '.jpg');

        if (!isJPG) {
          this.$message.warning('上传图片只能是 .png  .png  格式!');
        }
        if (!isLt20M) {
          this.$message.warning('上传文件大小不能超过 20MB!');
        }
        return isJPG && isLt20M;
      },
      headCancel() {
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      },
      // 保存
      headSave(boolean) {
        this.$refs.dataForm1.validate((valid) => {
          if (valid) {
            this.$loading();
            API.save({
              ...this.dataForm1,
              hdStatus:boolean ?'unaccept' : 'prepare',
              cnId:this.dataForm.id,
              rectifyPlanUrl:JSON.stringify(this.treeData3),
              rectifyUrl:JSON.stringify(this.treeData4),
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message:boolean ? '提交成功' :"保存成功",
                  type: "success",
                });
                if (boolean) {
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                }else {
                  this.getPrjbasicinfoDetail()
                }
                this.$loading().close();
              }
            }).catch(err=>{
              this.$loading().close();
            })
          }
        });

      },
      // 根据ID查表单详情
      getPrjbasicinfoDetail() {
        detail({
          id: this.dataForm.id
        }).then((res) => {
          if (res.data.code == 200) {
            this.dataForm = res.data.data;
            this.treeData=JSON.parse(this.dataForm.hdUrl)
            if(JSON.stringify(res.data.data.cnAssessmentVO) != '{}'){
              this.dataForm2 = res.data.data.cnAssessmentVO;
            }
            if(this.formType == 'view' &&JSON.stringify(res.data.data.cnRectifyVO) != '{}'){
              this.dataForm1 = res.data.data.cnRectifyVO;
            }
            if(this.dataForm.hdStatus == 'unrectify' &&JSON.stringify(res.data.data.cnRectifyVO) != '{}'){
              this.dataForm1 = res.data.data.cnRectifyVO;
            }
            if(res.data.data.cnAssessmentVO.assessmentUrl){
              this.treeData1=JSON.parse(res.data.data.cnAssessmentVO.assessmentUrl)
            }
            if(res.data.data.cnAssessmentVO.punishUrl){
              this.treeData2=JSON.parse(res.data.data.cnAssessmentVO.punishUrl)
            }
            if(this.formType == 'view' && res.data.data.cnRectifyVO.rectifyPlanUrl){
              this.treeData3=JSON.parse(res.data.data.cnRectifyVO.rectifyPlanUrl)
            }
            if(this.dataForm.hdStatus == 'unrectify'  && res.data.data.cnRectifyVO.rectifyPlanUrl){
              this.treeData3=JSON.parse(res.data.data.cnRectifyVO.rectifyPlanUrl)
              this.$set(this.dataForm1,'treeData3',this.treeData3)
            }
            if(this.formType == 'view' && res.data.data.cnRectifyVO.rectifyUrl){
              this.treeData4=JSON.parse(res.data.data.cnRectifyVO.rectifyUrl)
            }
            if(this.dataForm.hdStatus == 'unrectify' && res.data.data.cnRectifyVO.rectifyUrl){
              this.treeData4=JSON.parse(res.data.data.cnRectifyVO.rectifyUrl)
              this.$set(this.dataForm1,'treeData4',this.treeData4)
            }
            if(this.dataForm2.rectifyPlanFlag =='y'){
              this.$set(this.rules,'treeData3',[{
                required: true,
                message: '请上传整改计划',
                trigger: 'change'
              }])
            }
          }
        })
        API.List({
          cnId: this.dataForm.id,
          size:9999,
          current:1,
        }).then(res=>{
          this.historyList=res.data.data.records
        })
      },
      getNodeClick(node) {
        this.selectNode = node;
        this.showFile(node);
      },
      handleRemove(file, fileList) {
        let list = new Array();
        fileList.forEach((i) => {
          if (i.response) {
            list.push({
              extension: i.response.data.type,
              id: i.response.attachId,
              originalName: file.response.data.name,
              name: i.name,
              link: i.response.data.link
            });
          } else {
            list.push({
              extension: i.type,
              id: i.attachId,
              link: i.link,
              name: i.name,
              originalName: i.originalName,
              url: i.url
            });
          }
        })
        this.treeData3 = list;
        this.dataForm1.treeData3 = list;
      },
      handleRemove1(item, index) {
        this.treeData4.splice(index, 1);
      },
      showFile(item) {
        this.fileUrl =
          process.env.VUE_APP_BASE_KKFILEURL+"?url=" +
          encodeURIComponent(Base64.encode(item.link));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      },
    }
  }

</script>
<style lang="scss" scoped>
  .formContentBox {
    padding: 12px;
    height: calc(100% - 76px);
  }

  .formMain {
    width: 100%;
    overflow: auto;
    height: 100%;
  }
  .step-box{
    background-color: #ffffff;
    width: 125px;
  }
  .form-box{
    background-color: #ffffff;
    width: calc(100% - 135px);
    height: 100%;
    overflow: scroll;
  }
  .list-decoration{
    text-decoration: underline;
  }



  .leftRightBtn {
    margin: 211px 12px;
  }
  .checklist-edit{
    display: flex;
    height: 100%;
    justify-content: space-between;
  }



   .content {
    display: flex;
    align-items: center;
    .img-content {
      width: 188px;
      height: 100px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;
      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

  }
</style>
